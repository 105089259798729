<template>
  <div class="search">
    <div class="search-title">{{ keyword }} - 搜索结果</div>
    <div class="search-content">
      <div class="search-item" v-for="l in list" :key="l.id">
        <div class="search-item-title">{{ l.type_name }}</div>
        <div class="search-item-list">
          <template></template>
          <div class="list-item" v-for="cL in l.list" :key="cL.id">
            <div class="list-item-left">
              <img :src="baseUrl + (cL.img && cL.img.file_path)" alt="" />
            </div>
            <div class="list-item-middle">
              <div class="item-name scroll-hidden">{{ cL.name }}</div>
              <div class="item-mes scroll-hidden">
                {{ cL.mes }}
              </div>
            </div>
            <div class="list-item-right" @click="toDetail(cL, l.id)">
              <i class="el-icon-view"></i>查看
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getModuleBaseSearch } from "@/api/base.js";

export default {
  name: "Search",
  data() {
    return {
      loading: false, // 加载loading
      keyword: "", // 搜索内容
      currentPage: 1, // 当前页
      list: [],
    };
  },
  created() {
    let query = this.$route.query;
    this.keyword = query.keyword;

    // this.getModuleBaseSearch();
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        let query = val.query;
        this.keyword = query.keyword;
      },
    },
    keyword() {
      this.getModuleBaseSearch();
    },
  },
  methods: {
    toDetail(item, pId) {
      // page_customized
      const { id, module_code } = item;
      let name = "";
      if (module_code === "LawEnforcementTools") {
        // 课程
        name = "DetailTwo";
      } else {
        // if (page_customized === "DiscussTheIndustry") {
        //   name = "FormTwo";
        // } else {
        //   name = "DetailOne";
        // }
        name = "DetailOne";
      }
      this.$router.push({
        name,
        query: {
          module_code: module_code,
          id: pId,
          cid: id,
        },
      });
    },
    getModuleBaseSearch() {
      this.loading = true;
      getModuleBaseSearch({ searchwhere: this.keyword })
        .then((res) => {
          this.list = res.data.filter((el) => el.list.length > 0);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .search {
    padding: 2% 20%;

    .search-title {
      font-size: 24px;
    }
    .search-content {
      .search-item {
        .search-item-title {
        }
        .search-item-list {
          .list-item {
          }
          .list-item-left {
            width: 120px;
            img {
            }
          }
          .list-item-middle {
            width: calc(100% - 120px - 86px);
            .item-name {
            }
            .item-mes {
            }
          }
          .list-item-right {
            width: 86px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .search {
    padding: 2% 2% 4%;

    .search-title {
      font-size: 20px;
    }
    .search-content {
      .search-item {
        .search-item-title {
        }
        .search-item-list {
          .list-item {
          }
          .list-item-left {
            width: 84px;
          }
          .list-item-middle {
            width: calc(100% - 84px - 60px);
            .item-name {
            }
            .item-mes {
            }
          }
          .list-item-right {
            width: 60px;
          }
        }
      }
    }
  }
}
.search {
  overflow-y: auto;
  .search-title {
    font-weight: 700;
  }
  .search-content {
    .search-item {
      margin-top: 16px;
      .search-item-title {
        color: #007ffc;
        font-weight: 700;
      }
      .search-item-list {
        .list-item {
          display: flex;
          border: 1px solid #bdbdbd;
          margin-top: 10px;
        }
        .list-item-left {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .list-item-middle {
          .item-name {
            padding: 10px;
            font-weight: 700;
            overflow-x: scroll;
            white-space: nowrap;
          }
          .item-mes {
            padding: 0 10px 10px;
            font-size: 12px;
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #585858;
            overflow: auto;
          }
        }
        .list-item-right {
          background: #007cf9;
          font-size: 14px;
          font-family: SimSun;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          i {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>